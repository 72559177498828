/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useState, useEffect } from "react";

function EditCredentials() {
  const [tableData, setTableData] = useState([]);
  const [table, setTable] = useState([]);

  const addRow = () => {
    // Assuming each row is an object with some data, adjust this structure as needed
    const newRowData = {
      id: table.length + 1,
      type: null,
      url: null,
      portno: null,
      username: null,
      password: null,
      delegatedacc: null,
    };

    setTableData([...tableData, newRowData]);

    const newRow = {
      id: table.length + 1,

      type: (
        <Select
          id="type"
          name="type"
          onChange={(event) => handleStatusChange(table.length, event)}
          style={{ width: "100px" }}
        >
          <MenuItem value="https://facebook.com">Facebook</MenuItem>
          <MenuItem value="https://instagram.com">Instagram</MenuItem>
          <MenuItem value="https://linkedin.com">Linkedin</MenuItem>
          <MenuItem value="https://x.com">X</MenuItem>
          <MenuItem value="https://pinterest.com">Pinterest</MenuItem>
          <MenuItem value=" ">Other</MenuItem>
        </Select>
      ),

      url: (
        <TextField
          id="url"
          name="url"
          placeholder="Enter URL"
          variant="standard"
          onChange={(event) => handleUrlChange(table.length, event)}
        />
      ),

      portno: <TextField id="port" name="port" placeholder="Enter Port No." variant="standard" />,

      username: (
        <TextField id="username" name="username" placeholder="Enter Username" variant="standard" />
      ),

      password: (
        <TextField id="password" name="password" placeholder="Enter Password" variant="standard" />
      ),
      delegatedacc: (
        <TextField
          id="delegatedacc"
          name="delegatedacc"
          placeholder="Enter Delegated Acc."
          variant="standard"
        />
      ),
      action: (
        <Button variant="outlined" color="secondary" onClick={() => removeRow(table.length)}>
          X
        </Button>
      ),
    };
    setTable([...table, newRow]);
  };

  const removeRow = (id) => {
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    const updatedTable = table.filter((row) => row.id !== id);
    setTable(updatedTable);
  };

  const handleStatusChange = (id, event) => {
    const updatedTableData = tableData.map((row) => {
      if (row.id === id) {
        if (event.target.value != " ") {
          return { ...row, url: event.target.value };
        } else {
          return { ...row, url: event.target.value };
        }
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleUrlChange = (id, event) => {
    const updatedTableData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, url: event.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  useEffect(() => {
    addRow();
    return () => {
      // Cleanup logic (optional)
      console.log("Component will unmount. Cleanup logic here.");
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Authors Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { header: "type", accessor: "type", width: "30%", align: "left" },
                      { header: "url", accessor: "url", width: "30%", align: "left" },
                      { header: "portno", accessor: "portno", width: "30%", align: "left" },
                      { header: "username", accessor: "username", width: "30%", align: "left" },
                      { header: "password", accessor: "password", width: "30%", align: "left" },
                      {
                        header: "delegatedacc",
                        accessor: "delegatedacc",
                        width: "30%",
                        align: "left",
                      },
                      { header: "", accessor: "action", width: "30%", align: "left" },
                    ],
                    rows: table,
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Button variant="contained" sx={{ backgroundColor: "#dc8418", margin: 2 }} onClick={addRow}>
          + Add New Row
        </Button>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditCredentials;
