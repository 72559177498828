import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../images/logo.png";

export default function ButtonAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#ffffff" }}>
        <Toolbar>
          <Typography
            variant="h1"
            component="img"
            src={logo}
            alt="Company Logo"
            sx={{ width: "auto", height: 50, marginRight: 2 }}
          />

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, color: "grey", fontSize: 15, fontWeight: "bold", marginTop: 1 }}
          >
            Customer Credential Entry Form
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
