import React, { useState, useEffect } from "react";
import {
  styled,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Container,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ButtonAppBar from "./button-app-bar";
import AlertBanner from "./alert-banner";
import { useParams } from "react-router-dom";
import Preloader from "./preloader";
import Typography from "@mui/material/Typography";

const StyledTableContainer = styled(TableContainer)({
  margin: "auto", // Center the table
});

const StyledInputField = styled(TextField)({
  width: "100%", // Make the input fields take up the full width of the cell
});

const YourTableComponent = () => {
  const { email, uuid } = useParams();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCredentials = () => {
    // Make your API call here
    setLoading(true);
    fetch("http://localhost:8000/credentials/" + email + "/" + uuid)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        // Handle the API response data
        setTableData(data["data"]);
      })
      .catch((error) => {
        // Handle API call errors
        setLoading(false);
        console.error("Error:", error);
      });
  };

  const deleteCredential = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(
        "http://localhost:8000/credentials/" + email + "/" + uuid + "/" + id,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers if needed
          },
        }
      );

      if (!response.ok) {
        // Handle non-successful responses
        setLoading(false);
        console.error("POST request failed:", response.statusText);
        return;
      }

      const responseData = await response.json();
      setLoading(false);
      console.log("POST request successful:", responseData);
      getCredentials();
      // Handle the response data as needed
    } catch (error) {
      console.error("Error during POST request:", error);
    }
  };

  const defaultValues = {
    type: "",
    url: "",
    portno: "",
    username: "",
    password: "",
    delegated_acc: "",
    serial_no: null,
  };

  const saveCredentials = async () => {
    tableData.forEach((obj) => {
      // Check if the keys exist, and if not, create them with default values
      Object.keys(defaultValues).forEach((key) => {
        if (!(key in obj)) {
          obj[key] = defaultValues[key];
        }
      });
    });

    console.log(tableData);

    try {
      setLoading(true);
      const response = await fetch("http://localhost:8000/credentials/" + email + "/" + uuid, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers if needed
        },
        body: JSON.stringify(tableData),
      });

      if (!response.ok) {
        // Handle non-successful responses
        console.error("POST request failed:", response.statusText);
        return;
      }

      const responseData = await response.json();
      setLoading(false);
      console.log("POST request successful:", responseData);
      //getCredentials();
      // Handle the response data as needed
    } catch (error) {
      console.error("Error during POST request:", error);
    }
  };

  const handleDeleteClick = () => {
    // Make your API call here
    fetch("http://localhost:8000")
      .then((response) => response.json())
      .then((data) => {
        // Handle the API response data
        console.log(data);
      })
      .catch((error) => {
        // Handle API call errors
        console.error("Error:", error);
      });
  };

  const addRow = () => {
    // Assuming each row is an object with some data, adjust this structure as needed
    var newRow = {};
    if (tableData.length !== 0) {
      newRow = {
        id: tableData[tableData.length - 1].id + 1,
        serial_no: tableData[tableData.length - 1].serial_no + 1,
        name: "New Row",
      };
    } else {
      newRow = { id: 1, serial_no: 1, name: "New Row" };
    }
    setTableData([...tableData, newRow]);
  };

  const removeRow = (id) => {
    //const updatedTableData = tableData.filter((row) => row.id !== id);
    deleteCredential(id);
    //setTableData(updatedTableData);
  };

  const handleStatusChange = (id, event) => {
    const updatedTableData = tableData.map((row) => {
      if (row.id === id) {
        if (event.target.value != "Other") {
          let typeValue = "Other";
          let urlValue = "";
          switch (event.target.value) {
            case "Facebook":
              typeValue = "Facebook";
              urlValue = "https://facebook.com";
              break;
            case "Instagram":
              typeValue = "Instagram";
              urlValue = "https://instagram.com";
              break;
            case "Linkedin":
              typeValue = "Linkedin";
              urlValue = "https://linkedin.com";
              break;
            case "X":
              typeValue = "X";
              urlValue = "https://x.com";
              break;
            case "Pinterest":
              typeValue = "Pinterest";
              urlValue = "https://pinterest.com";
              break;
            default:
              typeValue = "Other";
              urlValue = "";
          }
          return { ...row, url: urlValue, type: typeValue };
        } else {
          return { ...row, url: "", type: "Other" };
        }
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleUrlChange = (id, event) => {
    const updatedTableData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, url: event.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handlePortNoChange = (id, event) => {
    const updatedTableData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, portno: event.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleUsernameChange = (id, event) => {
    const updatedTableData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, username: event.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handlePasswordChange = (id, event) => {
    const updatedTableData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, password: event.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  const handleDelegatedAccChange = (id, event) => {
    const updatedTableData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, delegated_acc: event.target.value };
      }
      return row;
    });
    setTableData(updatedTableData);
  };

  useEffect(async () => {
    getCredentials();
    return () => {
      // Cleanup logic (optional)
      console.log("Component will unmount. Cleanup logic here.");
    };
  }, []);

  return (
    <Container maxwidth="xs">
      <ButtonAppBar></ButtonAppBar>
      <AlertBanner
        severity="info"
        message="ATTENTION! Please double-check the credential type when creating a new entry. Accuracy matters for seamless access"
      ></AlertBanner>
      <StyledTableContainer component={Paper} sx={{ marginTop: 2, overflowX: "true" }}>
        <Table>
          {/* <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Port</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Password</TableCell>
              <TableCell>Delegated Acc.</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      flexGrow: 1,
                      color: "grey",
                      fontSize: 15,
                      fontWeight: "regular",
                      marginTop: 1,
                    }}
                  >
                    {row.serial_no}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Select
                    id="type"
                    name="type"
                    value={row.type}
                    onChange={(event) => handleStatusChange(row.id, event)}
                    style={{ width: "100px" }}
                  >
                    <MenuItem value="Facebook">Facebook</MenuItem>
                    <MenuItem value="Instagram">Instagram</MenuItem>
                    <MenuItem value="Linkedin">Linkedin</MenuItem>
                    <MenuItem value="X">X</MenuItem>
                    <MenuItem value="Pinterest">Pinterest</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    id="url"
                    name="url"
                    placeholder="Enter URL"
                    variant="standard"
                    value={row.url}
                    onChange={(event) => handleUrlChange(row.id, event)}
                    style={{ width: "140px" }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="portno"
                    name="portno"
                    placeholder="Enter Port No."
                    variant="standard"
                    value={row.portno}
                    onChange={(event) => handlePortNoChange(row.id, event)}
                    style={{ width: "130px" }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="username"
                    name="username"
                    placeholder="Enter Username"
                    variant="standard"
                    value={row.username}
                    onChange={(event) => handleUsernameChange(row.id, event)}
                    style={{ width: "130px" }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="password"
                    name="password"
                    placeholder="Enter Password"
                    variant="standard"
                    value={row.password}
                    onChange={(event) => handlePasswordChange(row.id, event)}
                    style={{ width: "130px" }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="delegated_acc"
                    name="delegate_dacc"
                    placeholder="Enter Delegated Acc."
                    variant="standard"
                    value={row.delegated_acc}
                    onChange={(event) => handleDelegatedAccChange(row.id, event)}
                    style={{ width: "130px" }}
                  />
                </TableCell>
                <TableCell>
                  <Button variant="outlined" color="secondary" onClick={() => removeRow(row.id)}>
                    X
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Button
        variant="contained"
        sx={{ backgroundColor: "#dc8418", fontColor: "#ffffff", color: "#ffffff", margin: 2 }}
        onClick={addRow}
      >
        + Add New Row
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{ fontColor: "#ffffff", color: "#ffffff" }}
        onClick={saveCredentials}
      >
        Save
      </Button>
      {loading && <Preloader />}
    </Container>
  );
};

export default YourTableComponent;
