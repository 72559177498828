// AlertBanner.js
import React from "react";
import { styled } from "@mui/system";
import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";

const useStyles = styled((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
}));

const AlertBanner = ({ severity, message }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity={severity}>{message}</Alert>
    </div>
  );
};

AlertBanner.propTypes = {
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default AlertBanner;
